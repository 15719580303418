import { type FC } from "react";

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faClock } from "@fortawesome/pro-duotone-svg-icons/faClock";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Page, PageStatusEnum } from "@app_schema";

import { Pill } from "@styled/pill";

const PageStatusProcessing: FC = () => (
  <Pill color="indigo">
    <FontAwesomeIcon spin icon={faSpinner} /> <div>Processing</div>
  </Pill>
);
const PageStatusInitialized: FC = () => (
  <Pill color="slate">
    <FontAwesomeIcon icon={faClock} /> <div>Initialized</div>
  </Pill>
);

const PageStatusProcessed: FC = () => (
  <Pill color="emerald">
    <FontAwesomeIcon icon={faCheckCircle} /> <div>Processed</div>
  </Pill>
);

export const PageStatus: FC<{
  page: Pick<Page, "id" | "status">;
}> = ({ page }) => {
  switch (page.status) {
    case PageStatusEnum.Processing:
      return <PageStatusProcessing />;
    case PageStatusEnum.Initialized:
      return <PageStatusInitialized />;
    case PageStatusEnum.Processed:
      return <PageStatusProcessed />;
  }
};
