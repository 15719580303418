import { type FC } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faOpenid } from "@fortawesome/free-brands-svg-icons/faOpenid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Connection__Provider } from "@app_schema";

const ICON: Record<Connection__Provider, IconProp> = {
  [Connection__Provider.Github]: faGithub,
  [Connection__Provider.Intuit]: faOpenid,
};

export const ConnectionIcon: FC<{
  provider: Connection__Provider;
}> = ({ provider }) => <FontAwesomeIcon icon={ICON[provider]} />;
