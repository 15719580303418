import { type FC } from "react";

import { MenuNavLink } from "./menu_nav_link";
import { DASHBOARD_BILLING_ROUTE } from "@application/components/routes/dashboard_billing_route";
import { DASHBOARD_CONNECTIONS_ROUTE } from "@application/components/routes/dashboard_conections_route";
import { DASHBOARD_RUNS_ROUTE } from "@application/components/routes/dashboard_runs_route";
import { DASHBOARD_SETTINGS_ROUTE } from "@application/components/routes/dashboard_settings_route";
import { DASHBOARD_SKILLS_ROUTE } from "@application/components/routes/dashboard_skills_route";
import { DASHBOARD_TRIGGERS_ROUTE } from "@application/components/routes/dashboard_triggers_route";
import { DASHBOARD_USERS_ROUTE } from "@application/components/routes/dashboard_users_route";
import { DASHBOARD_VAULTS_ROUTE } from "@application/components/routes/dashboard_vaults_route";
import { DASHBOARD_WORKFLOWS_ROUTE } from "@application/components/routes/dashboard_workflows_route";
import { LOGOUT_ROUTE } from "@application/components/routes/logout_route";

const MAIN_LINKS = [
  DASHBOARD_WORKFLOWS_ROUTE,
  DASHBOARD_TRIGGERS_ROUTE,
  DASHBOARD_RUNS_ROUTE,
  DASHBOARD_SKILLS_ROUTE,
  DASHBOARD_USERS_ROUTE,
  DASHBOARD_CONNECTIONS_ROUTE,
  DASHBOARD_VAULTS_ROUTE,
  DASHBOARD_SETTINGS_ROUTE,
  DASHBOARD_BILLING_ROUTE,
];

const OTHER_LINKS = [LOGOUT_ROUTE];

export const MenuLinks: FC = () => (
  <div className="flex h-full flex-col justify-between gap-2">
    <div className="flex flex-col gap-2">
      {MAIN_LINKS.map((link, key) => (
        <MenuNavLink key={key} {...link} />
      ))}
    </div>
    <div className="flex flex-col gap-2">
      {OTHER_LINKS.map((link, key) => (
        <MenuNavLink key={key} {...link} />
      ))}
    </div>
  </div>
);
