import { type HTMLProps, forwardRef } from "react";

import { clsx } from "clsx";

export const THead = forwardRef<
  HTMLTableSectionElement,
  HTMLProps<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={clsx("divide-y divide-slate-200", className)}
    {...props}
  />
));
