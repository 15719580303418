import { type FC } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Percentage } from "./percentage";
import { Remaining } from "./remaining";

export const FileStatus: FC<{
  loaded?: number;
  total?: number;
}> = ({ loaded, total }) => {
  if (loaded === undefined || total === undefined)
    return <FontAwesomeIcon icon={faSpinner} spin />;

  if (!total) return;

  const ratio = loaded / total;

  return (
    <div className="text-slate-600">
      <Remaining loaded={loaded} total={total} /> <Percentage ratio={ratio} />
    </div>
  );
};
