import { createContext } from "react";

type Blob = {
  signed_id: string;
  filename: string;
};

type Upload = {
  id: string;
  file: File;
};

export const FileContext = createContext<{
  uploads: Upload[];
  onSelect(files: File[]): void;
  onUpload(file: File, blob: Blob): void;
}>({
  uploads: [],
  onSelect: () => {},
  onUpload: () => {},
});
