import { type FC } from "react";
import { Link } from "react-router-dom";

import { type ScheduleFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

export const SchedulesListAttributes: FC<{
  schedule: ScheduleFragment;
}> = ({ schedule }) => (
  <Attributes>
    <Attribute name="Workflow">
      <Link to={`/dashboard/workflows/${schedule.workflow.id}`}>
        {schedule.workflow.name}
      </Link>
    </Attribute>
    <Attribute name="Frequency">{schedule.frequency}</Attribute>
  </Attributes>
);
