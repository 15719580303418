import { type FC, useState } from "react";

import { useDashboardSchedulesListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Fields } from "@styled/fields";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { Notification } from "@styled/notification";
import { SearchInputField } from "@styled/search_input_field";

import { RunRecent } from "./run_recent";
import { ScheduleDestroyButton } from "./schedule_destroy_button";
import { SchedulesListAttributes } from "./schedules_list_attributes";
import { DashboardTriggersContainer } from "./triggers_container";

export const DashboardSchedulesList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading } = useDashboardSchedulesListQuery({
    variables: { query: useDebounce(query), mine },
  });
  const schedules = data?.schedules.filter(({ deleted }) => !deleted);

  return (
    <DashboardTriggersContainer loading={loading}>
      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />
        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <Notification color="slate">
        Schedules offer the ability to trigger a workflow run at any time you'd
        like. You can generate multiple schedules to run your workflows at
        various times. Be sure to label your webhooks so you can easily identify
        them in the future.
      </Notification>

      <List>
        {schedules?.map((schedule) => (
          <ListItem key={schedule.id}>
            <ListItemContent>
              <ListItemText>
                <ListItemTitle>
                  <span>{schedule.description}</span>
                </ListItemTitle>
                <ListItemSummary>
                  <SchedulesListAttributes schedule={schedule} />
                </ListItemSummary>
                {schedule.runs.length > 0 && (
                  <ListItemSummary>
                    <RunRecent runs={schedule.runs} />
                  </ListItemSummary>
                )}
              </ListItemText>

              <ListItemControls>
                <ScheduleDestroyButton schedule={schedule} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </DashboardTriggersContainer>
  );
};
