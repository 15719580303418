import { type FC } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { useDashboardConnectionsDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { DateTimeFormatter } from "@styled/date_time_formatter";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { ConnectionDestoryButton } from "./connection_destroy_button";
import { ConnectionRefreshButton } from "./connection_refresh_button";
import { ConnectionTitle } from "./connection_title";

export const DashboardConnectionDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useDashboardConnectionsDetailsQuery({
    variables: { id: id! },
  });

  const connection = data?.connection;
  if (!connection) return null;

  return (
    <Page loading={loading} error={error}>
      <Headline title={<ConnectionTitle connection={connection} />}>
        <ModifyLink to={`/dashboard/connections/${connection.id}/edit`} />
        <ConnectionRefreshButton connection={connection} />
        <ConnectionDestoryButton
          connection={connection}
          onDestroy={() => navigate("/dashboard/connections")}
        />
      </Headline>

      <Attribute name="Name">{connection.name}</Attribute>

      <Attribute name="UID">{connection.uid}</Attribute>

      {connection.accessToken && (
        <Attribute name="Access Token">{connection.accessToken}</Attribute>
      )}

      {connection.refreshToken && (
        <Attribute name="Refresh Token">{connection.refreshToken}</Attribute>
      )}

      {connection.expiresAt && (
        <Attribute name="Expires At">
          <DateTimeFormatter iso={connection.expiresAt} />
        </Attribute>
      )}
    </Page>
  );
};
