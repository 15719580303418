import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardStepsDetailsDocument,
  type DashboardStepsDetailsQuery,
  type DashboardStepsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_STEPS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardStepsDetailsQuery,
    DashboardStepsDetailsQueryVariables
  >({
    query: DashboardStepsDetailsDocument,
    variables: {
      workflowID: props.params.workflowID!,
      stepID: props.params.stepID!,
    },
    fetchPolicy: "network-only",
  });

  return data;
};
