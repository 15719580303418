import type { FC } from "react";

import { faRefresh } from "@fortawesome/pro-duotone-svg-icons/faRefresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type ConnectionFragment,
  useConnectionRefreshMutation,
} from "@app_schema";

import { Button } from "@styled/button";

export const ConnectionRefreshButton: FC<{
  connection: ConnectionFragment;
  onRefresh?(): void;
}> = ({ connection, onRefresh }) => {
  const [execute, { loading }] = useConnectionRefreshMutation();
  const refresh = async () => {
    await execute({ variables: { id: connection.id } });
    if (onRefresh) onRefresh();
  };

  return (
    <Button type="button" loading={loading} onClick={refresh}>
      <FontAwesomeIcon icon={faRefresh} />
      Refresh
    </Button>
  );
};
