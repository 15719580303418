import { type FC } from "react";

import { type Vault, useVaultDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const VaultsDestroyButton: FC<{
  vault: Pick<Vault, "id" | "name">;
  onDestroy?(): void;
}> = ({ vault, onDestroy }) => {
  const [execute, { loading }] = useVaultDestoryMutation();

  const destroy = async () => {
    await execute({ variables: { id: vault.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={<>Are you sure you want to delete "{vault.name}"?</>}
    />
  );
};
