import { type FC, type FormEvent, useState } from "react";

import { type FetchResult } from "@apollo/client";

import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons/faPaperPlane";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "./button";
import { ButtonFile } from "./button_file";
import { FieldGroup } from "./field_group";
import { FileUploader } from "./file_uploader";
import { Form } from "./form";
import { Input } from "./input";

type Blob = {
  signed_id: string;
  filename: string;
};

const ChatFormAttachment: FC<{
  blob: Blob;
  onDelete(): void;
}> = ({ blob, onDelete }) => (
  <div className="flex gap-2 rounded-md bg-slate-200 p-2 text-slate-400">
    <span>{blob.filename}</span>
    <button type="button" onClick={onDelete}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  </div>
);

export const ChatForm: FC<{
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  chat(_: { message: string; fileIDs?: string[] }): Promise<FetchResult>;
}> = ({ placeholder = "Message...", disabled, loading, chat }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [blobs, setBlobs] = useState<Blob[]>([]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (files.length > 0) return;

    const { errors } = await chat({
      message,
      fileIDs: blobs.map((blob) => blob.signed_id),
    });
    if (errors) return;

    setFiles([]);
    setBlobs([]);
    setMessage("");
  };

  return (
    <Form onSubmit={onSubmit}>
      <FieldGroup>
        <ButtonFile
          disabled={disabled}
          onSelect={(value) => setFiles((files) => [...files, ...value])}
          className="-mr-px w-16 rounded-none rounded-l-md focus:z-30"
        />
        <Input
          disabled={disabled}
          value={message}
          placeholder={placeholder}
          onChange={(event) => setMessage(event.target.value)}
          className="z-20 rounded-none"
        />
        <Button
          type="submit"
          disabled={disabled || files.length > 0}
          loading={loading}
          className="-ml-px w-16 rounded-none rounded-r-md focus:z-30"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </FieldGroup>

      {files.map((file, key) => (
        <FileUploader
          file={file}
          key={key}
          onUpload={(file, blob) => {
            setBlobs((blobs) => [...blobs, blob]);
            setFiles((files) => files.filter((entry) => entry !== file));
          }}
        />
      ))}

      {blobs.length > 0 && (
        <div className="flex gap-2">
          {blobs.map((blob) => (
            <ChatFormAttachment
              key={blob.signed_id}
              blob={blob}
              onDelete={() => {
                setBlobs((blobs) => blobs.filter((entry) => entry !== blob));
              }}
            />
          ))}
        </div>
      )}
    </Form>
  );
};
