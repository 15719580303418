import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { Connection__Provider, type WebhookErrors } from "@app_schema";

import { ConnectionSelectField } from "./connection_select_field";

export const WebhooksFormGithubFields: FC<{
  errors?: WebhookErrors | null;
}> = () => {
  const form = useFormContext();
  const connectionID = form.watch("connectionID");

  return (
    <>
      <ConnectionSelectField
        provider={Connection__Provider.Github}
        {...form.register("connectionID", { required: "required" })}
        placeholder={!connectionID}
        id="connection_id"
        label="Connection:"
      />
    </>
  );
};
