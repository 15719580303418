import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";
import { faLock } from "@fortawesome/pro-duotone-svg-icons/faLock";

import { type WebhookErrors } from "@app_schema";

import { InputField } from "@styled/input_field";

export const WebhooksFormCustomFields: FC<{
  errors?: WebhookErrors | null;
}> = ({ errors }) => {
  const form = useFormContext();

  return (
    <>
      <InputField
        {...form.register("username", { required: "required" })}
        id="user"
        disabled
        type="text"
        label="Username:"
        placeholder="Username"
        icon={faIdCardClip}
        errors={errors}
      />
      <InputField
        {...form.register("password", { required: "required" })}
        id="password"
        disabled
        type="text"
        label="Password:"
        placeholder="Password"
        icon={faLock}
        errors={errors}
      />
    </>
  );
};
