import { type FC, useState } from "react";

import {
  type ConnectionFragment,
  Connection__Provider,
  useDashboardConnectionsListQuery,
} from "@app_schema";

import { CSRF_PARAM, CSRF_TOKEN } from "@application/config/csrf";

import { useDebounce } from "@application/hooks/use_debounce";

import { Button } from "@styled/button";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { ConnectionDestoryButton } from "./connection_destroy_button";
import { ConnectionIcon } from "./connection_icon";
import { ConnectionName } from "./connection_name";
import { ConnectionTitle } from "./connection_title";

const ConnectButton: FC<{
  provider: Connection__Provider;
  url: string;
}> = ({ provider, url }) => (
  <form method="post" action={url}>
    <input type="hidden" name={CSRF_PARAM!} value={CSRF_TOKEN!} />
    <Button full type="submit" color="indigo">
      <ConnectionIcon provider={provider} />
      <ConnectionName provider={provider} />
    </Button>
  </form>
);

export const ConnectionListItem: FC<{
  connection: ConnectionFragment;
}> = ({ connection }) => (
  <ListItem>
    <ListItemContent>
      <ListItemText>
        <ListItemTitle>
          <ConnectionTitle connection={connection} />
        </ListItemTitle>
        <ListItemSummary>UID: {connection.uid}</ListItemSummary>
      </ListItemText>
      <ListItemControls>
        <ViewLink to={`/dashboard/connections/${connection.id}`} />
        <ConnectionDestoryButton connection={connection} />
      </ListItemControls>
    </ListItemContent>
  </ListItem>
);

export const DashboardConnectionsList: FC = () => {
  const [query, setQuery] = useState<string | undefined>();

  const { data } = useDashboardConnectionsListQuery({
    variables: { query: useDebounce(query) },
  });

  if (!data) return null;

  const connections = data.connections.filter(({ deleted }) => !deleted);

  const CONNECTS = [
    { provider: Connection__Provider.Github, url: data.githubURL },
    { provider: Connection__Provider.Intuit, url: data.intuitURL },
  ];

  return (
    <Page>
      <Headline title="Connections">
        {CONNECTS.map(({ provider, url }, key) => (
          <ConnectButton key={key} provider={provider} url={url} />
        ))}
      </Headline>

      <SearchInputField query={query} onSearch={setQuery} />

      <List>
        {connections.map((connection) => (
          <ConnectionListItem key={connection.id} connection={connection} />
        ))}
      </List>
    </Page>
  );
};
