import { type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDashboardVaultsDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { ByteSize } from "@styled/byte_size";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { VaultsDocuments } from "./vault_documents";
import { VaultsDestroyButton } from "./vaults_destroy_button";

export const DashboardVaultsDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data } = useDashboardVaultsDetailsQuery({
    variables: { id: id! },
  });

  if (!data) return null;
  const vault = data.vault;

  return (
    <Page>
      <Headline title={vault.name}>
        <ModifyLink to={`/dashboard/vaults/${vault.id}/edit`} />
        <VaultsDestroyButton
          vault={vault}
          onDestroy={() => {
            navigate("/dashboard/vaults");
          }}
        />
      </Headline>

      <Attribute name="Name">{vault.name}</Attribute>

      <Attribute name="Description">{vault.description || "N/A"}</Attribute>

      <Attribute name="Size">
        <ByteSize bytes={vault.bytesize} />
      </Attribute>

      <VaultsDocuments vault={vault} />
    </Page>
  );
};
