import { type FC } from "react";
import { Link } from "react-router-dom";

import { type WebhookFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

export const WebhooksListAttributes: FC<{
  webhook: WebhookFragment;
}> = ({ webhook }) => (
  <Attributes>
    <Attribute name="Workflow">{webhook.workflow.name}</Attribute>

    <Attribute name="Connection">
      {webhook.connection && (
        <Link to={`/dashboard/connections/${webhook.connection.id}`}>
          {webhook.connection.name} (UID: {webhook.connection.uid})
        </Link>
      )}
    </Attribute>
  </Attributes>
);
