import { type HTMLProps, forwardRef } from "react";

import { clsx } from "clsx";

export const Table = forwardRef<HTMLTableElement, HTMLProps<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <table
      ref={ref}
      className={clsx("w-full divide-y divide-slate-100", className)}
      {...props}
    />
  ),
);
