import { tw } from "./tw";
import { FormState } from "./types";

export const SELECT_STYLE = tw`rounded-md border text-slate-800 outline-none ring-0 focus:ring-4 disabled:bg-slate-100`;

export const SELECT_STATE_STYLE = {
  [FormState.Default]: tw`border-slate-200 focus:border-indigo-500 focus:ring-indigo-300/50`,
  [FormState.Valid]: tw`border-emerald-500 ring-emerald-300`,
  [FormState.Error]: tw`border-rose-500 ring-rose-300`,
};

export const SELECT_PLAIN_STYLE = tw`border-none bg-white ring-0 hover:bg-slate-100 focus:border-none focus:ring-0`;

export const FIELD_STYLE = tw`block w-full rounded-md border px-3 py-2 text-slate-800 outline-none ring-0 transition placeholder:text-slate-400 focus:ring-4 disabled:bg-slate-100 disabled:text-slate-400`;

export const FIELD_STATE_STYLE = {
  [FormState.Default]: tw`border-slate-200 focus:border-indigo-500 focus:ring-indigo-300/50`,
  [FormState.Valid]: tw`border-emerald-500 ring-emerald-300 focus:border-emerald-500 focus:ring-emerald-300/50`,
  [FormState.Error]: tw`border-rose-500 ring-rose-300 focus:border-rose-500 focus:ring-rose-300/50`,
};

export const FIELD_FILE_STYLE = tw`file:-my-2 file:-ml-3 file:mr-3 file:border-none file:bg-slate-100 file:px-3 file:py-2`;
