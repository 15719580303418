import { type SelectHTMLAttributes, forwardRef } from "react";

import {
  type Connection__Provider,
  useDashboardConnectionsListQuery,
} from "@app_schema";

import { type BaseFieldProps } from "@styled/base_field";
import { SelectField } from "@styled/select_field";

type T = HTMLSelectElement;
type P = BaseFieldProps &
  SelectHTMLAttributes<T> & {
    placeholder?: boolean;
    provider?: Connection__Provider;
  };

export const ConnectionSelectField = forwardRef<T, P>(
  ({ placeholder, disabled, provider, ...props }, ref) => {
    const { data, loading } = useDashboardConnectionsListQuery();
    const connections = data?.connections.filter(
      (connection) =>
        !connection.deleted && (!provider || connection.provider === provider),
    );

    return (
      <SelectField {...props} ref={ref} disabled={disabled ?? loading}>
        <option value="" disabled={!placeholder}>
          - Connection -
        </option>

        {connections?.map((connection) => (
          <option key={connection.id} value={connection.id}>
            {connection.name} (UID: {connection.uid})
          </option>
        ))}
      </SelectField>
    );
  },
);
