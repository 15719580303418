import { createRoot } from "react-dom/client";

import { App } from "./app";

addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  if (!container) return;

  const root = createRoot(container);
  root.render(<App />);
});
