import type { FC } from "react";

import {
  type ConnectionFragment,
  useConnectionDestroyMutation,
} from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const ConnectionDestoryButton: FC<{
  connection: ConnectionFragment;
  onDestroy?(): void;
}> = ({ connection, onDestroy }) => {
  const [execute, { loading }] = useConnectionDestroyMutation();
  const destroy = async () => {
    await execute({ variables: { id: connection.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={
        <>Are you you sure you want to delete “{connection.name}”?</>
      }
    >
      Delete
    </DestroyButton>
  );
};
