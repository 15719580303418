import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardConnectionsDetailsDocument,
  type DashboardConnectionsDetailsQuery,
  type DashboardConnectionsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONNECTIONS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardConnectionsDetailsQuery,
    DashboardConnectionsDetailsQueryVariables
  >({
    query: DashboardConnectionsDetailsDocument,
    variables: { id: props.params.id! },
  });

  return data;
};
