import {
  DashboardConnectionsListDocument,
  type DashboardConnectionsListQuery,
  type DashboardConnectionsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONNECTIONS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardConnectionsListQuery,
    DashboardConnectionsListQueryVariables
  >({
    query: DashboardConnectionsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
