import { type FC } from "react";

import { PageFragment, useDashboardDocumentViewerQuery } from "@app_schema";

import { HTML } from "@styled/html";
import { Prose } from "@styled/prose";

import { PageStatus } from "./page_status";

const DocumentPage: FC<{
  page: PageFragment;
}> = ({ page }) => (
  <>
    <div className="flex items-center justify-between gap-2">
      <strong>Page #{page.number}</strong>
      <PageStatus page={page} />
    </div>

    <hr />

    {page.html && (
      <Prose>
        <HTML html={page.html} />
      </Prose>
    )}
  </>
);

export const DocumentViewer: FC<{
  vaultID: string;
  documentID: string;
}> = ({ documentID, vaultID }) => {
  const { data } = useDashboardDocumentViewerQuery({
    variables: {
      documentID,
      vaultID,
    },
  });
  const pages = data?.vault?.document?.pages;
  return pages?.map((page) => <DocumentPage page={page} key={page.id} />);
};
