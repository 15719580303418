import { type FC, type ReactNode } from "react";
import { Link } from "react-router-dom";

export const NodeLink: FC<{
  children: ReactNode;
  to: string;
  target?: string;
}> = (props) => (
  <Link
    {...props}
    className="rounded-md px-3 py-2 text-slate-500 hover:bg-indigo-500 hover:text-white disabled:bg-transparent disabled:text-slate-500/50"
  />
);
