import {
  DashboardVaultsListDocument,
  type DashboardVaultsListQuery,
  type DashboardVaultsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_VAULTS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardVaultsListQuery,
    DashboardVaultsListQueryVariables
  >({
    query: DashboardVaultsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
