import { type FC } from "react";

const UNITS = ["B", "KB", "MB", "GB", "TB"];
const UNIT = 1024;

const FORMATTER = new Intl.NumberFormat(undefined, {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const ByteSize: FC<{
  bytes?: number | null; // bytes
}> = ({ bytes }) => {
  if (bytes === null || bytes === undefined) return;

  let size = bytes;
  let unit = 0;

  for (unit = 0; unit < UNITS.length; unit++) {
    if (size < UNIT) break;
    size /= UNIT;
  }

  return (
    <>
      {FORMATTER.format(size)} {UNITS[unit]}
    </>
  );
};
