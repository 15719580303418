import { type FC, useState } from "react";

import { useDashboardVaultsListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { ByteSize } from "@styled/byte_size";
import { Fields } from "@styled/fields";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { VaultsDestroyButton } from "./vaults_destroy_button";

export const DashboardVaultsList: FC = () => {
  const [query, setQuery] = useState<string | undefined>();
  const [mine, setMine] = useState<boolean | undefined>();

  const { data } = useDashboardVaultsListQuery({
    variables: { query: useDebounce(query), mine },
  });

  return (
    <Page>
      <Headline title="Vaults">
        <NewLink to="/dashboard/vaults/new">New</NewLink>
      </Headline>

      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />

        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <List>
        {data?.vaults
          .filter((vault) => !vault.deleted)
          .map((vault) => (
            <ListItem key={vault.id}>
              <ListItemContent>
                <ListItemText>
                  <ListItemTitle>{vault.name}</ListItemTitle>
                  <ListItemSummary>
                    <ByteSize bytes={vault.bytesize} />
                  </ListItemSummary>
                </ListItemText>
                <ListItemControls>
                  <ViewLink to={`/dashboard/vaults/${vault.id}`} />
                  <VaultsDestroyButton vault={vault} />
                </ListItemControls>
              </ListItemContent>
            </ListItem>
          ))}
      </List>
    </Page>
  );
};
