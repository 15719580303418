import { type FC } from "react";
import { useNavigate } from "react-router";

import { type VaultInput, useVaultBuildMutation } from "@app_schema";

import { DashboardVaultsForm } from "./vaults_form";

export const DashboardVaultsNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading, data }] = useVaultBuildMutation();
  const errors = data?.vaults.build.errors;

  const save = async (input: VaultInput) => {
    const result = await execute({ variables: { input } });
    const vault = result.data?.vaults.build.vault;
    if (vault) navigate(`/dashboard/vaults/${vault.id}`);
  };

  return <DashboardVaultsForm save={save} errors={errors} loading={loading} />;
};
