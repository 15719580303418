import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { type StepErrors, type StepInput, StepKindEnum } from "@app_schema";

import { DashboardStepsFormActionBranchFieldset } from "./steps_form_action_branch_fieldset";
import { DashboardStepsFormActionCodeFieldset } from "./steps_form_action_code_fieldset";
import { DashboardStepsFormActionFormatterFieldset } from "./steps_form_action_formatter_fieldset";
import { DashboardStepsFormActionInputFieldset } from "./steps_form_action_input_fieldset";
import { DashboardStepsFormActionIteratorFieldset } from "./steps_form_action_iterator_fieldset";
import { DashboardStepsFormActionLearnFieldset } from "./steps_form_action_learn_fieldset";
import { DashboardStepsFormActionOutputFieldset } from "./steps_form_action_output_fieldset";
import { DashboardStepsFormActionPromptFieldset } from "./steps_form_action_prompt_fieldset";
import { DashboardStepsFormActionSpeechFieldset } from "./steps_form_action_speech_fieldset";
import { DashboardStepsFormActionTranscriptionFieldset } from "./steps_form_action_transcription_fieldset";

export const DashboardStepsFormActionFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const form = useFormContext<StepInput>();
  const kind = form.watch("kind");
  if (!kind) return null;

  switch (kind) {
    case StepKindEnum.Branch:
      return <DashboardStepsFormActionBranchFieldset errors={errors} />;
    case StepKindEnum.Code:
      return <DashboardStepsFormActionCodeFieldset errors={errors} />;
    case StepKindEnum.Formatter:
      return <DashboardStepsFormActionFormatterFieldset errors={errors} />;
    case StepKindEnum.Prompt:
      return <DashboardStepsFormActionPromptFieldset errors={errors} />;
    case StepKindEnum.Learn:
      return <DashboardStepsFormActionLearnFieldset errors={errors} />;
    case StepKindEnum.Output:
      return <DashboardStepsFormActionOutputFieldset errors={errors} />;
    case StepKindEnum.Input:
      return <DashboardStepsFormActionInputFieldset errors={errors} />;
    case StepKindEnum.Iterator:
      return <DashboardStepsFormActionIteratorFieldset errors={errors} />;
    case StepKindEnum.Speech:
      return <DashboardStepsFormActionSpeechFieldset errors={errors} />;
    case StepKindEnum.Transcription:
      return <DashboardStepsFormActionTranscriptionFieldset errors={errors} />;
  }
};
