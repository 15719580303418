import { type FC, ReactNode, useContext } from "react";

import { FileContext } from "./file_context";
import { FileUploader } from "./file_uploader";

type Progress = {
  loaded: number;
  total: number;
};

export const FileQueue: FC<{
  render(_: { file: File; progress?: Progress }): ReactNode;
}> = ({ render }) => {
  const { uploads, onUpload } = useContext(FileContext);

  return uploads.map(({ id, file }) => (
    <FileUploader key={id} file={file} onUpload={onUpload} children={render} />
  ));
};
