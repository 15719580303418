import { type ChangeEvent, type FC } from "react";
import { useFormContext } from "react-hook-form";

import { Connection__Provider, type WebhookErrors } from "@app_schema";

import { Field } from "@styled/field";
import { Group } from "@styled/group";
import { Label } from "@styled/label";
import { LabelCheckbox } from "@styled/label_checkbox";
import { Table } from "@styled/table";
import { TBody } from "@styled/tbody";
import { TD } from "@styled/td";
import { TH } from "@styled/th";
import { THead } from "@styled/thead";

import { ConnectionSelectField } from "./connection_select_field";

type Action = "Create" | "Update" | "Delete" | "Merge" | "Void";

type Option = {
  entity: string;
  actions: Action[];
};

const OPTIONS: Option[] = [
  { entity: "Account", actions: ["Create", "Update", "Merge", "Delete"] },
  { entity: "Customer", actions: ["Create", "Update", "Merge", "Delete"] },
  { entity: "Deposit", actions: ["Create", "Update", "Merge"] },
  { entity: "Invoice", actions: ["Create", "Update", "Void", "Delete"] },
  { entity: "Payment", actions: ["Create", "Update", "Void", "Delete"] },
  { entity: "Purchase", actions: ["Create", "Update", "Void", "Delete"] },
  { entity: "Transfer", actions: ["Create", "Update", "Void", "Delete"] },
  { entity: "Vendor", actions: ["Create", "Update", "Merge", "Delete"] },
];

const OptionCheckbox: FC<{
  option: Option;
}> = ({ option }) => {
  const form = useFormContext();

  const checked = option.actions.every((action) =>
    form.watch(`options.${option.entity}.${action}`),
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    for (const action of option.actions) {
      form.setValue(`options.${option.entity}.${action}`, checked);
    }
  };

  return (
    <LabelCheckbox checked={checked} onChange={onChange}>
      {option.entity}
    </LabelCheckbox>
  );
};

const ActionCheckbox: FC<{
  option: Option;
  action: Action;
}> = ({ option, action }) => {
  const form = useFormContext();

  return (
    <LabelCheckbox {...form.register(`options.${option.entity}.${action}`)}>
      {action}
    </LabelCheckbox>
  );
};

export const WebhooksFormIntuitFields: FC<{
  errors?: WebhookErrors | null;
}> = () => {
  const form = useFormContext();
  const connectionID = form.watch("connectionID");

  return (
    <>
      <ConnectionSelectField
        provider={Connection__Provider.Intuit}
        {...form.register("connectionID", { required: "required" })}
        placeholder={!connectionID}
        id="connection_id"
        label="Connection:"
      />

      <Field>
        <Label>Options</Label>
        <Group>
          <Table>
            <THead>
              <tr>
                <TH className="w-0">Entity</TH>
                <TH className="w-full">Actions</TH>
              </tr>
            </THead>
            <TBody>
              {OPTIONS.map((option, key) => (
                <tr key={key}>
                  <TD>
                    <OptionCheckbox option={option} />
                  </TD>
                  <TD>
                    <div className="flex gap-2">
                      {option.actions.map((action, key) => (
                        <ActionCheckbox
                          key={key}
                          option={option}
                          action={action}
                        />
                      ))}
                    </div>
                  </TD>
                </tr>
              ))}
            </TBody>
          </Table>
        </Group>
      </Field>
    </>
  );
};
