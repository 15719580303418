import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { type StepErrors, type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";

export const DashboardStepsFormActionLearnFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.learn");
    return () => unregister("action.learn");
  }, [register, unregister]);

  return (
    <Fieldset>
      <InputField
        {...register("action.learn.name", { required: "required" })}
        errors={errors ? (errors.messages["action.name"] ?? []) : null}
        id="step_action_learn_name"
        label="Name:"
        placeholder="Name"
      />

      <InputField
        {...register("action.learn.code", { required: "required" })}
        errors={errors ? (errors.messages["action.code"] ?? []) : null}
        id="step_action_learn_code"
        label="Code:"
        placeholder="Code"
      />

      <InputField
        {...register("action.learn.test", { required: "required" })}
        errors={errors ? (errors.messages["action.test"] ?? []) : null}
        id="step_action_learn_test"
        label="Test:"
        placeholder="Test"
      />

      <InputField
        {...register("action.learn.documentation", { required: "required" })}
        errors={
          errors ? (errors.messages["action.documentation"] ?? []) : undefined
        }
        id="step_action_learn_documentation"
        label="Documentation:"
        placeholder="Documentation"
      />
    </Fieldset>
  );
};
