import { type HTMLProps, forwardRef } from "react";

import { clsx } from "clsx";

export const TH = forwardRef<
  HTMLTableCellElement,
  HTMLProps<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={clsx("px-3 py-2 text-left font-semibold", className)}
    {...props}
  />
));
