import { type FC } from "react";

import { type DocumentFragment, type VaultFragment } from "@app_schema";

import { Button } from "@styled/button";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";

import { DocumentViewer } from "./document_viewer";

export const DocumentDialog: FC<{
  document: DocumentFragment;
  vault: VaultFragment;
  onClose(): void;
}> = ({ document, vault, onClose }) => {
  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{document.name}</ModalTitle>
        <ModalClose onClose={onClose} />
      </ModalHeader>
      <ModalContent>
        <DocumentViewer documentID={document.id} vaultID={vault.id} />
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
