import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
  Action__CodeImage,
  type StepErrors,
  type StepInput,
} from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { SelectField } from "@styled/select_field";
import { TextAreaField } from "@styled/text_area_field";

import { TemplateLanguageSelectField } from "./steps/template_language_select_field";

const IMAGES = [
  { name: "Python", value: Action__CodeImage.Python },
  { name: "Ruby", value: Action__CodeImage.Ruby },
  { name: "Scraper", value: Action__CodeImage.Scrape },
];

export const DashboardStepsFormActionCodeFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister, watch } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.code");
    return () => unregister("action.code");
  }, [register, unregister]);

  return (
    <Fieldset>
      <SelectField
        {...register("action.code.image", { required: "required" })}
        errors={errors ? (errors.messages["action.image"] ?? []) : null}
        id="step_action_execution_image"
        label="Image:"
      >
        <option disabled={!!watch("action.code.image")} value="">
          - Image -
        </option>
        {IMAGES.map(({ name, value }, key) => (
          <option value={value} key={key}>
            {name}
          </option>
        ))}
      </SelectField>

      <TemplateLanguageSelectField
        {...register("action.code.language", { required: "required" })}
        errors={errors ? (errors.messages["action.language"] ?? []) : null}
        id="step_action_code_language"
        placeholder={!watch("action.code.language")}
      />

      <TextAreaField
        mono
        {...register("action.code.template", { required: "required" })}
        errors={errors ? (errors.messages["action.template"] ?? []) : null}
        id="step_action_execution_template"
        label="Template:"
        placeholder="Template"
        rows={8}
      />
    </Fieldset>
  );
};
