import { type FC, type ReactNode, useState } from "react";

import { FileContext } from "./file_context";

type Blob = {
  signed_id: string;
  filename: string;
};

type Upload = {
  id: string;
  file: File;
};

export const FileManager: FC<{
  save(blob: Blob): void;
  children: ReactNode;
}> = ({ save, children }) => {
  const [uploads, setUploads] = useState<Upload[]>([]);

  const onSelect = (files: File[]) => {
    setUploads((uploads) => [
      ...uploads,
      ...files.map((file) => ({
        id: crypto.randomUUID(),
        file,
      })),
    ]);
  };

  const onUpload = (file: File, blob: Blob) => {
    save(blob);

    setUploads((uploads) => uploads.filter((upload) => upload.file !== file));
  };

  return (
    <FileContext.Provider
      value={{
        uploads,
        onSelect,
        onUpload,
      }}
      children={children}
    />
  );
};
