import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { faRabbitRunning } from "@fortawesome/pro-duotone-svg-icons/faRabbitRunning";

import {
  Action__SpeechFormat,
  Action__SpeechModel,
  Action__SpeechVoice,
  type StepErrors,
  type StepInput,
} from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";
import { SelectField } from "@styled/select_field";
import { TextAreaField } from "@styled/text_area_field";

const DEFAULT_MODEL = Action__SpeechModel.OpenaiTts;
const DEFAULT_FORMAT = Action__SpeechFormat.Aac;

export const DashboardStepsFormActionSpeechFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister, watch } = useFormContext<StepInput>();
  const model = watch("action.speech.model");
  const format = watch("action.speech.format");

  useEffect(() => {
    register("action.speech");
    return () => unregister("action.speech");
  }, [register, unregister]);

  return (
    <Fieldset>
      <TextAreaField
        {...register("action.speech.template", { required: "required" })}
        errors={errors ? (errors.messages["action.template"] ?? []) : null}
        id="step_action_speech_template"
        label="Template:"
        placeholder="Template"
        rows={8}
      />

      <SelectField
        defaultValue={DEFAULT_MODEL}
        {...register("action.speech.model", { required: "required" })}
        errors={errors ? (errors.messages["action.model"] ?? []) : null}
        id="step_action_speech_model"
        label="Model:"
      >
        <option disabled={!!model} value="">
          - Model -
        </option>

        <option value={Action__SpeechModel.OpenaiTts}>Open AI - TTS</option>
        <option value={Action__SpeechModel.OpenaiTtsHd}>
          Open AI - TTS-HD
        </option>
      </SelectField>

      <SelectField
        {...register("action.speech.voice", { required: "required" })}
        errors={errors ? (errors.messages["action.voice"] ?? []) : null}
        id="step_action_speech_voice"
        label="Voice:"
      >
        <option disabled={!!model} value="">
          - Voice -
        </option>

        <option value={Action__SpeechVoice.OpenaiAlloy}>Open AI - Alloy</option>
        <option value={Action__SpeechVoice.OpenaiEcho}>Open AI - Echo</option>
        <option value={Action__SpeechVoice.OpenaiFable}>Open AI - Fable</option>
        <option value={Action__SpeechVoice.OpenaiNova}>Open AI - Nova</option>
        <option value={Action__SpeechVoice.OpenaiOnyx}>Open AI - Onyx</option>
        <option value={Action__SpeechVoice.OpenaiShimmer}>
          Open AI - Shimmer
        </option>
      </SelectField>

      <InputField
        {...register("action.speech.speed", {
          min: 0.25,
          max: 4.0,
          valueAsNumber: true,
        })}
        errors={errors ? (errors.messages["action.temperature"] ?? []) : null}
        id="step_action_speech.temperature"
        step={0.25}
        icon={faRabbitRunning}
        type="number"
        label="Speed (optional):"
        placeholder="Speed"
      />

      <SelectField
        defaultValue={DEFAULT_FORMAT}
        {...register("action.speech.format")}
        errors={errors ? (errors.messages["action.format"] ?? []) : null}
        id="step_action_speech.formatter_kind"
        label="Format:"
      >
        <option disabled={!!format} value="">
          - Kind -
        </option>
        <option value={Action__SpeechFormat.Aac}>AAC</option>
        <option value={Action__SpeechFormat.Flac}>FLAC</option>
        <option value={Action__SpeechFormat.Mp3}>MP3</option>
        <option value={Action__SpeechFormat.Opus}>OPUS</option>
        <option value={Action__SpeechFormat.Pcm}>PCM</option>
        <option value={Action__SpeechFormat.Wav}>WAV</option>
      </SelectField>
    </Fieldset>
  );
};
