import { type FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { faTemperatureList } from "@fortawesome/pro-duotone-svg-icons/faTemperatureList";

import {
  Action__PromptModel,
  type Action__Prompt__MessageInput,
  Action__Prompt__MessageRole,
  FormatterKindEnum,
  type StepErrors,
  type StepInput,
} from "@app_schema";

import { JSONSchema } from "@application/types/json_schema";
import { JSONSchemaType } from "@application/types/json_schema_type";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";
import { JSONFormatter } from "@styled/json_formatter";
import { JSONSchemaField } from "@styled/json_schema_field";
import { SelectField } from "@styled/select_field";
import { Well } from "@styled/well";

import { DashboardStepsFormActionPromptMessagesFieldset } from "./steps_form_action_prompt_messages_fieldset";
import { DashboardStepsFormActionPromptToolsFieldset } from "./steps_form_action_prompt_tools_fieldset";

const DEFAULT_TEMPERATURE = 0.0;

const MODELS = [
  Action__PromptModel.AnthropicClaudeHaiku,
  Action__PromptModel.AnthropicClaudeSonnet,
  Action__PromptModel.AnthropicClaudeOpus,
  Action__PromptModel.GoogleGeminiPro,
  Action__PromptModel.GoogleGemini_1_5Pro,
  Action__PromptModel.GoogleGemini_1_5Flash,
  Action__PromptModel.GoogleGeminiUltra,
  Action__PromptModel.MistralSmall,
  Action__PromptModel.MistralMedium,
  Action__PromptModel.MistralLarge,
  Action__PromptModel.MistralCodestral,
  Action__PromptModel.OpenaiGpt_3_5Turbo,
  Action__PromptModel.OpenaiGpt_4,
  Action__PromptModel.OpenaiGpt_4Turbo,
  Action__PromptModel.OpenaiGpt_4O,
  Action__PromptModel.OpenaiO1Mini,
  Action__PromptModel.OpenaiO1Preview,
];

const NAMES: Record<Action__PromptModel, string> = {
  [Action__PromptModel.AnthropicClaudeHaiku]: "Anthropic - Claude Haiku",
  [Action__PromptModel.AnthropicClaudeSonnet]: "Anthropic - Claude Sonnet",
  [Action__PromptModel.AnthropicClaudeOpus]: "Anthropic - Claude Opus",
  [Action__PromptModel.GoogleGeminiPro]: "Google - Gemini Pro",
  [Action__PromptModel.GoogleGemini_1_5Pro]: "Google - Gemini 1.5 Pro",
  [Action__PromptModel.GoogleGemini_1_5Flash]: "Google - Gemini 1.5 Flash",
  [Action__PromptModel.GoogleGeminiUltra]: "Google - Gemini Ultra",
  [Action__PromptModel.MistralSmall]: "Mistral - Small",
  [Action__PromptModel.MistralMedium]: "Mistral - Medium",
  [Action__PromptModel.MistralLarge]: "Mistral - Large",
  [Action__PromptModel.MistralCodestral]: "Mistral - Codestral",
  [Action__PromptModel.OpenaiGpt_3_5Turbo]: "OpenAI - GPT 3.5 Turbo",
  [Action__PromptModel.OpenaiGpt_4]: "OpenAI - GPT 4",
  [Action__PromptModel.OpenaiGpt_4Turbo]: "OpenAI - GPT 4 Turbo",
  [Action__PromptModel.OpenaiGpt_4O]: "OpenAI - GPT 4o",
  [Action__PromptModel.OpenaiO1Mini]: "OpenAI - o1-mini",
  [Action__PromptModel.OpenaiO1Preview]: "OpenAI - o1-preview",
};

const DEFAULT_SCHEMA: JSONSchema = {
  type: JSONSchemaType.Object,
  title: "",
  description: "",
  properties: {},
  required: [],
};

const DEFAULT_MESSAGES: Action__Prompt__MessageInput[] = [
  {
    template: "",
    role: Action__Prompt__MessageRole.User,
    attachments: [],
  },
];

const DashboardStepsFormActionPromptFormatterJSONFieldset: FC = () => {
  const { control } = useFormContext<StepInput>();

  return (
    <Controller
      control={control}
      defaultValue={DEFAULT_SCHEMA}
      name="action.prompt.formatter.options"
      render={({ field: { onChange, value: schema } }) => {
        return (
          <Fieldset>
            <JSONSchemaField schema={schema} onChange={onChange} />
            <Well>
              <JSONFormatter json={schema} />
            </Well>
          </Fieldset>
        );
      }}
    />
  );
};

export const DashboardStepsFormActionPromptFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister, watch } = useFormContext<StepInput>();
  const model = watch("action.prompt.model");
  const kind = watch("action.prompt.formatter.kind");

  useEffect(() => {
    register("action.prompt");
    return () => unregister("action.prompt");
  }, [register, unregister]);

  return (
    <Fieldset>
      <SelectField
        {...register("action.prompt.model", { required: "required" })}
        errors={errors ? (errors.messages["action.model"] ?? []) : null}
        id="step_action_prompt_model"
        label="Model:"
      >
        <option disabled={!!model} value="">
          - Model -
        </option>

        {MODELS.map((model) => (
          <option key={model} value={model}>
            {NAMES[model]}
          </option>
        ))}
      </SelectField>

      <InputField
        defaultValue={DEFAULT_TEMPERATURE}
        {...register("action.prompt.temperature", {
          required: "required",
          min: 0,
          max: 1,
          valueAsNumber: true,
        })}
        errors={errors ? (errors.messages["action.temperature"] ?? []) : null}
        id="step_action_prompt_temperature"
        step={0.1}
        icon={faTemperatureList}
        type="number"
        label="Temperature:"
        placeholder="Temperature"
      />

      <Controller
        defaultValue={DEFAULT_MESSAGES}
        name="action.prompt.messages"
        render={() => <DashboardStepsFormActionPromptMessagesFieldset />}
      />

      <Controller
        name="action.prompt.tools"
        render={() => <DashboardStepsFormActionPromptToolsFieldset />}
      />

      <SelectField
        {...register("action.prompt.formatter.kind", { required: "required" })}
        errors={errors ? (errors.messages["action.kind"] ?? []) : null}
        id="step_action_prompt_formatter_kind"
        label="Formatter (Kind):"
      >
        <option disabled={!!kind} value="">
          - Kind -
        </option>
        <option value={FormatterKindEnum.Text}>TEXT</option>
        <option value={FormatterKindEnum.Json}>JSON</option>
        <option value={FormatterKindEnum.Skill}>Skill</option>
      </SelectField>

      {kind === FormatterKindEnum.Json && (
        <DashboardStepsFormActionPromptFormatterJSONFieldset />
      )}
    </Fieldset>
  );
};
