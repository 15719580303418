import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { faIdCardClip as faName } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import {
  type ConnectionErrors,
  type ConnectionFragment,
  type ConnectionInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

export const DashboardConnectionsForm: FC<{
  connection?: ConnectionFragment;
  errors?: ConnectionErrors | null;
  loading?: boolean;
  save(_: ConnectionInput): void;
}> = ({ connection, loading, errors, save }) => {
  const form = useForm<ConnectionInput>({
    defaultValues: connection ? { name: connection.name } : undefined,
  });

  const onSubmit = async (input: ConnectionInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("name", { required: "required" })}
            id="name"
            type="text"
            label="Name:"
            placeholder="e.g. XYZ"
            icon={faName}
            errors={errors}
          />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
