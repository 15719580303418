import {
  DragEvent,
  type FC,
  type ReactNode,
  useContext,
  useRef,
  useState,
} from "react";

import { clsx } from "clsx";

import { faArrowUpFromBracket } from "@fortawesome/pro-duotone-svg-icons/faArrowUpFromBracket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FileContext } from "./file_context";

const FilePickerButton: FC<{
  onSelect(files: File[]): void;
}> = ({ onSelect }) => {
  const input = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState<number>(0);

  return (
    <>
      <input
        key={key}
        type="file"
        multiple
        ref={input}
        onChange={(event) => {
          const files = event.target.files;
          if (files) onSelect([...files]);
          setKey((key) => key + 1);
        }}
        style={{ display: "none" }}
      />

      <button
        type="button"
        onClick={() => input?.current?.click()}
        className="text-indigo-600 hover:underline"
      >
        Select Files
      </button>
    </>
  );
};

const FilePickerContainer: FC<{
  children: ReactNode;
  onSelect(files: File[]): void;
}> = ({ onSelect, ...props }) => {
  const [dragging, setDragging] = useState<boolean>(false);

  const onDragOver = (event: DragEvent) => {
    if (!event.dataTransfer.types.includes("Files")) return;

    event.preventDefault();
    event.stopPropagation();

    setDragging(true);
  };

  const onDragLeave = (event: DragEvent) => {
    if (!event.dataTransfer.types.includes("Files")) return;

    event.preventDefault();
    event.stopPropagation();

    setDragging(false);
  };

  const onDrop = (event: DragEvent) => {
    if (dragging) setDragging(!dragging);

    if (!event.dataTransfer.types.includes("Files")) return;

    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files) onSelect([...files]);
  };

  return (
    <div
      {...props}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={clsx(
        "flex w-full flex-col items-center justify-center gap-4 rounded-md p-8",
        dragging
          ? "border border-solid border-indigo-600 bg-indigo-50"
          : "border border-dashed border-slate-200 bg-slate-50",
      )}
    />
  );
};

export const FilePicker: FC = () => {
  const { onSelect } = useContext(FileContext);

  return (
    <>
      <FilePickerContainer onSelect={onSelect}>
        <div className="text-slate-400">
          <FontAwesomeIcon icon={faArrowUpFromBracket} size="4x" />
        </div>
        <div className="font-medium text-slate-600">
          Drag & Drop or <FilePickerButton onSelect={onSelect} />
        </div>
      </FilePickerContainer>
    </>
  );
};
