import { type FC } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import {
  type VaultInput,
  useDashboardVaultsDetailsQuery,
  useVaultChangeMutation,
} from "@app_schema";

import { DashboardVaultsForm } from "./vaults_form";

export const DashboardVaultsEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: defaults } = useDashboardVaultsDetailsQuery({
    variables: { id: id! },
  });
  const [execute, { loading, data }] = useVaultChangeMutation();
  const errors = data?.vault.change.errors;

  const save = async (input: VaultInput) => {
    const result = await execute({ variables: { id: id!, input } });
    const vault = result.data?.vault.change.vault;
    if (vault) navigate(`/dashboard/vaults/${vault.id}`);
  };

  if (!defaults) return;
  const vault = defaults.vault;

  return (
    <DashboardVaultsForm
      save={save}
      errors={errors}
      loading={loading}
      vault={vault}
    />
  );
};
