import { type FC, type ReactNode } from "react";
import { useActiveStorage } from "react-activestorage";

import { Progress } from "./progress";

type Blob = {
  filename: string;
  signed_id: string;
};

export const FileUploader: FC<{
  file: File;
  children?(props: {
    file: File;
    progress: {
      loaded: number;
      total: number;
    };
  }): ReactNode;
  onUpload(file: File, blob: Blob): void;
}> = ({ file, children, onUpload }) => {
  const { progress } = useActiveStorage(file, async ({ blob }) => {
    if (!blob) return;
    onUpload(file, blob);
  });

  if (!progress) return;

  if (children) return children({ file, progress });
  else return <Progress value={progress.loaded / progress.total} />;
};
