import { type FC, type ReactNode } from "react";
import { Link } from "react-router-dom";

import { style } from "./button_style";
import { type Color } from "./types";

export const ButtonLink: FC<{
  to: string;
  target?: string;
  color?: Color;
  full?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  children: ReactNode;
  className?: string;
}> = ({ color, full, size, className, ...props }) => (
  <Link {...props} className={style({ color, full, size, className })} />
);
