import { type FC } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import {
  type ConnectionInput,
  useConnectionChangeMutation,
  useDashboardConnectionsDetailsQuery,
} from "@app_schema";

import { DashboardConnectionsForm } from "./connections_form";

export const DashboardConnectionsEdit: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [execute, { loading, data }] = useConnectionChangeMutation();
  const { data: defaults } = useDashboardConnectionsDetailsQuery({
    variables: { id: id! },
  });
  const errors = data?.connection.change.errors;

  const save = async (input: ConnectionInput) => {
    const result = await execute({ variables: { id: id!, input } });
    const connection = result.data?.connection.change.connection;
    if (connection) navigate(`/dashboard/connections/${connection.id}`);
  };

  return (
    <DashboardConnectionsForm
      save={save}
      errors={errors}
      loading={loading}
      connection={defaults?.connection}
    />
  );
};
