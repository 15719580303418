import { type FC, type ReactNode } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";

import { Control } from "./control";
import { Field } from "./field";
import { Hint } from "./hint";
import { Label } from "./label";
import { Sentence } from "./sentence";
import { Tooltip } from "./tooltip";
import { FormState } from "./types";

type Errors = Record<string, string[] | string | null> | string[];

export type BaseFieldProps = {
  id?: string;
  name: string;
  tooltip?: { tip: ReactNode };
  hint?: ReactNode;
  label?: ReactNode;
  icon?: IconProp;
  errors?: Errors | null;
};

export const BaseField: FC<
  BaseFieldProps & {
    children({ state }: { state: FormState }): ReactNode;
  }
> = ({ id, label, icon, errors, hint, name, tooltip, children }) => {
  const messages = errors instanceof Array ? errors : errors?.[name];

  console.log("name", name, "messages", messages);

  const state: FormState = (() => {
    if (errors === undefined || errors === null) return FormState.Default;

    if (messages instanceof Array)
      return messages.length > 0 ? FormState.Error : FormState.Valid;
    else return messages ? FormState.Error : FormState.Valid;
  })();

  return (
    <Field>
      {label && (
        <Label htmlFor={id}>
          {label} {tooltip && <Tooltip {...tooltip} />}
        </Label>
      )}
      <Control icon={icon} state={state}>
        {children({ state })}
      </Control>
      {hint && <Hint color="slate">{hint}</Hint>}
      {messages && messages.length > 0 && (
        <Hint color="rose">
          <Sentence>{messages}</Sentence>
        </Hint>
      )}
    </Field>
  );
};

BaseField.displayName = "BaseField";
