import { type FC } from "react";

const FORMATTER = new Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const Percentage: FC<{
  ratio: number; // 0.5 is 50%
}> = ({ ratio }) => FORMATTER.format(ratio);
