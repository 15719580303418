import { type FC } from "react";

import { Connection__Provider } from "@app_schema";

const NAME: Record<Connection__Provider, string> = {
  [Connection__Provider.Github]: "GitHub",
  [Connection__Provider.Intuit]: "Intuit",
};

export const ConnectionName: FC<{
  provider: Connection__Provider;
}> = ({ provider }) => <>{NAME[provider]}</>;
