import { type InputHTMLAttributes, type ReactNode, forwardRef } from "react";

import { Checkbox } from "./checkbox";
import { Label } from "./label";

type T = HTMLInputElement;
type P = InputHTMLAttributes<T> & {
  label?: ReactNode;
};

export const LabelCheckbox = forwardRef<T, P>(({ children, ...props }, ref) => (
  <Label className="flex items-center gap-2 rounded-md border border-slate-200 px-3 py-2">
    <Checkbox ref={ref} {...props} />
    {children}
  </Label>
));
