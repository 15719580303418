import { type FC } from "react";

import { faFile } from "@fortawesome/pro-duotone-svg-icons/faFile";
import { faFileAudio } from "@fortawesome/pro-duotone-svg-icons/faFileAudio";
import { faFileCsv } from "@fortawesome/pro-duotone-svg-icons/faFileCsv";
import { faFileExcel } from "@fortawesome/pro-duotone-svg-icons/faFileExcel";
import { faFileGif } from "@fortawesome/pro-duotone-svg-icons/faFileGif";
import { faFileImage } from "@fortawesome/pro-duotone-svg-icons/faFileImage";
import { faFileJpg } from "@fortawesome/pro-duotone-svg-icons/faFileJpg";
import { faFileMov } from "@fortawesome/pro-duotone-svg-icons/faFileMov";
import { faFilePdf } from "@fortawesome/pro-duotone-svg-icons/faFilePdf";
import { faFilePng } from "@fortawesome/pro-duotone-svg-icons/faFilePng";
import { faFilePowerpoint } from "@fortawesome/pro-duotone-svg-icons/faFilePowerpoint";
import { faFileVideo } from "@fortawesome/pro-duotone-svg-icons/faFileVideo";
import { faFileWord } from "@fortawesome/pro-duotone-svg-icons/faFileWord";
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

const icon = (type?: string | null) => {
  if (!type) return faFile;

  if (type.match(/csv$/)) return faFileCsv;
  if (type.match(/gif$/)) return faFileGif;
  if (type.match(/jpg$/)) return faFileJpg;
  if (type.match(/jpg$/)) return faFileJpg;
  if (type.match(/mov$/)) return faFileMov;
  if (type.match(/png$/)) return faFilePng;
  if (type.match(/pdf$/)) return faFilePdf;
  if (type.match(/excel$/)) return faFileExcel;
  if (type.match(/powerpoint$/)) return faFilePowerpoint;
  if (type.match(/word$/)) return faFileWord;

  if (type.match(/^audio/)) return faFileAudio;
  if (type.match(/^image/)) return faFileImage;
  if (type.match(/^video/)) return faFileVideo;

  return faFile;
};

export const FileIcon: FC<
  {
    type?: string | null;
    className?: string;
  } & Omit<FontAwesomeIconProps, "icon" | "type">
> = ({ type, ...props }) => <FontAwesomeIcon icon={icon(type)} {...props} />;
