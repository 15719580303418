import { type FC, useEffect, useState } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActiveStorageAttachmentKindEnum,
  type AttachmentFragment,
} from "@app_schema";

import { Button } from "./button";
import { ButtonAnchor } from "./button_anchor";
import { FileIcon } from "./file_icon";
import { Modal } from "./modal";
import { ModalClose } from "./modal_close";
import { ModalContent } from "./modal_content";
import { ModalFooter } from "./modal_footer";
import { ModalHeader } from "./modal_header";
import { ModalTitle } from "./modal_title";
import { Well } from "./well";

const FileDialogImage: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment: { url } }) => <img className="w-full" src={url} />;

const FileDialogAudio: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment: { url } }) => (
  <audio className="w-full" controls src={url} />
);

const FileDialogVideo: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment: { url } }) => (
  <video className="w-full" controls src={url} />
);

const FileDialogText: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment: { url } }) => {
  const [text, setText] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const response = await fetch(url);
      const text = await response.text();
      setText(text);
    })();
  }, [url]);

  if (text === undefined) return <FontAwesomeIcon icon={faSpinner} spin />;
  return (
    <Well>
      <pre>{text}</pre>
    </Well>
  );
};

const FileDialogContent: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment }) => {
  switch (attachment.kind) {
    case ActiveStorageAttachmentKindEnum.Audio:
      return <FileDialogAudio attachment={attachment} />;
    case ActiveStorageAttachmentKindEnum.Image:
      return <FileDialogImage attachment={attachment} />;
    case ActiveStorageAttachmentKindEnum.Video:
      return <FileDialogVideo attachment={attachment} />;
    case ActiveStorageAttachmentKindEnum.Text:
      return <FileDialogText attachment={attachment} />;
    case ActiveStorageAttachmentKindEnum.Other:
      return null;
  }
};

export const FileDialog: FC<{
  attachment: AttachmentFragment;
  onClose(): void;
}> = ({ attachment, onClose }) => (
  <Modal onClose={onClose}>
    <ModalHeader>
      <ModalTitle>
        <FileIcon type={attachment.type} /> {attachment.filename}
      </ModalTitle>
      <ModalClose onClose={onClose} />
    </ModalHeader>
    <ModalContent>
      <FileDialogContent attachment={attachment} />
    </ModalContent>
    <ModalFooter>
      <ButtonAnchor href={attachment.url} download>
        Download
      </ButtonAnchor>
      <Button type="button" color="slate" onClick={onClose}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);
