import { type FC } from "react";

import { Connection } from "@app_schema";

import { ConnectionIcon } from "./connection_icon";

export const ConnectionTitle: FC<{
  connection: Pick<Connection, "name" | "provider">;
}> = ({ connection }) => (
  <div className="flex items-center gap-2">
    <ConnectionIcon provider={connection.provider} /> {connection.name}
  </div>
);
