import { type HTMLProps, forwardRef } from "react";

import { clsx } from "clsx";

export const TD = forwardRef<
  HTMLTableCellElement,
  HTMLProps<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={clsx("px-3 py-2 text-left font-normal", className)}
    {...props}
  />
));
