import { type FC, useState } from "react";

import { faSquarePlus } from "@fortawesome/pro-duotone-svg-icons/faSquarePlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDashboardUsersListQuery } from "@app_schema";

import { Avatar } from "@styled/avatar";
import { ButtonLink } from "@styled/button_link";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemIcon } from "@styled/list_item_icon";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";

import { UsersDestroyButton } from "./users_destroy_button";

export const DashboardUsersList: FC = () => {
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading, error, refetch } = useDashboardUsersListQuery({
    variables: { query },
  });
  const retry = () => refetch();

  const users = data?.users.filter(({ deleted }) => !deleted);

  return (
    <Page loading={loading} error={error} retry={retry}>
      <Headline title="Users">
        <ButtonLink to="/dashboard/users/new">
          <FontAwesomeIcon icon={faSquarePlus} /> New
        </ButtonLink>
      </Headline>

      <SearchInputField query={query} onSearch={setQuery} />

      <List>
        {users?.map((user) => (
          <ListItem key={user.id}>
            <ListItemContent>
              <ListItemIcon>
                <Avatar user={user} />
              </ListItemIcon>

              <ListItemText>
                <ListItemTitle>{user.name}</ListItemTitle>
                <ListItemSummary>{user.email}</ListItemSummary>
              </ListItemText>

              <ListItemControls>
                <UsersDestroyButton user={user} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};
