import { type FC, type FormEvent, useState } from "react";

import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import {
  type VaultErrors,
  type VaultFragment,
  type VaultInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";
import { TextAreaField } from "@styled/text_area_field";

export const DashboardVaultsForm: FC<{
  vault?: VaultFragment | null;
  loading?: boolean;
  errors?: VaultErrors | null;
  save(_: VaultInput): void;
}> = ({ vault, loading, errors, save }) => {
  const [name, setName] = useState<string>(vault?.name ?? "");
  const [description, setDescription] = useState(vault?.description ?? "");
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!name) return;

    save({
      name,
      description,
    });
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        {errors?.base && (
          <Notification color="rose">
            <Sentence>{errors.base}</Sentence>
          </Notification>
        )}

        <InputField
          required
          id="name"
          name="name"
          type="text"
          label="Name:"
          placeholder="Name"
          icon={faIdCardClip}
          value={name}
          errors={errors}
          onChange={(event) => setName(event.target.value)}
        />

        <TextAreaField
          id="description"
          name="description"
          label="Description:"
          placeholder="Description"
          value={description}
          errors={errors}
          onChange={(event) => setDescription(event.target.value)}
        />

        <Button type="submit" loading={loading}>
          Save
        </Button>
      </Form>
    </Page>
  );
};
