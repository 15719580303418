import { type FC } from "react";

import {
  DocumentFragment,
  VaultFragment,
  useVaultDocumentDetachMutation,
} from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const DocumentDestroyButton: FC<{
  vault: VaultFragment;
  document: DocumentFragment;
  onDestroy?(): void;
}> = ({ vault, document, onDestroy }) => {
  const [execute, { loading }] = useVaultDocumentDetachMutation({
    variables: {
      vaultID: vault.id,
      documentID: document.id,
    },
  });

  const destroy = async () => {
    await execute();
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={<>Are you you sure you want to delete “{document.name}”?</>}
    >
      Delete
    </DestroyButton>
  );
};
