import { type FC } from "react";

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faClock } from "@fortawesome/pro-duotone-svg-icons/faClock";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Document, DocumentStatusEnum } from "@app_schema";

import { Pill } from "@styled/pill";

const DocumentStatusProcessing: FC = () => (
  <Pill color="indigo">
    <FontAwesomeIcon spin icon={faSpinner} /> <div>Processing</div>
  </Pill>
);
const DocumentStatusInitialized: FC = () => (
  <Pill color="slate">
    <FontAwesomeIcon icon={faClock} /> <div>Initialized</div>
  </Pill>
);

const DocumentStatusProcessed: FC = () => (
  <Pill color="emerald">
    <FontAwesomeIcon icon={faCheckCircle} /> <div>Processed</div>
  </Pill>
);

export const DocumentStatus: FC<{
  document: Pick<Document, "id" | "status">;
}> = ({ document }) => {
  switch (document.status) {
    case DocumentStatusEnum.Processing:
      return <DocumentStatusProcessing />;
    case DocumentStatusEnum.Initialized:
      return <DocumentStatusInitialized />;
    case DocumentStatusEnum.Processed:
      return <DocumentStatusProcessed />;
  }
};
