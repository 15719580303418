import { type FC, useState } from "react";

import { faEye } from "@fortawesome/pro-duotone-svg-icons/faEye";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DocumentFragment, VaultFragment } from "@app_schema";

import { Button } from "@styled/button";

import { DocumentDialog } from "./document_dialog";

export const DocumentBrowseButton: FC<{
  vault: VaultFragment;
  document: DocumentFragment;
}> = ({ vault, document }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onToggle = () => setVisible((visible) => !visible);

  return (
    <>
      <Button type="button" color="slate" onClick={onToggle}>
        <FontAwesomeIcon icon={faEye} /> Browse
      </Button>

      {visible && (
        <DocumentDialog vault={vault} document={document} onClose={onToggle} />
      )}
    </>
  );
};
