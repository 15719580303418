import { FC, useState } from "react";

import { DateTime } from "luxon";

const HMS: FC<{
  duration: number;
}> = ({ duration }) => {
  const h = Math.trunc(duration / 3600);
  const m = Math.trunc((duration % 3600) / 60);
  const s = Math.trunc(duration % 60);

  if (h > 0) return `${h}h ${m}m ${s}s`;
  if (m > 0) return `${m}m ${s}s`;
  if (s > 0) return `${s}s`;

  return "0s";
};

export const Remaining: FC<{
  loaded: number;
  total: number;
}> = ({ loaded, total }) => {
  if (loaded === total) return null;
  if (loaded > total) return null;
  if (loaded === 0) return null;
  if (total === 0) return null;

  const [start] = useState<DateTime>(() => DateTime.now());
  const now = DateTime.now();

  // e.g. for ellapsed = 6 min total = 80 bytes loaded = 60 bytes
  // 6 min * (80 byte - 60 byte) / 60 byte) = 2 min
  const elapsed: number = now.diff(start).as("seconds");
  const remaining: number = (elapsed * (total - loaded)) / loaded;

  return (
    <>
      <HMS duration={remaining} /> remaining
    </>
  );
};
